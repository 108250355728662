var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-2 font-large-1 h1"},[_vm._v(" Greetings! Outlet "+_vm._s(_vm.userData.name)+", ")]),_c('b-card',{staticClass:"p-lg-1 mb-1 mx-n3 rounded-0"},[_c('div',{staticClass:"row justify-content-center align-items-center"},[_c('div',{staticClass:"col-xs-10 col-sm-10 col-md-7 col-lg-7"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require('@/assets/images/logo/delivery.png')}})]),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-5 col-lg-5"},[_c('DeliveryPrice')],1)])]),_c('b-row',{staticClass:"mx-1 my-2"},[_c('h2',{staticClass:"text-primary font-large-1 d-none d-sm-inline-block"},[_vm._v(" Guidelines ")])]),_c('b-row',_vm._l((_vm.guidelineList),function(guidelineItem,index){return _c('b-col',{key:index,staticClass:"mb-2",attrs:{"cols":"12","lg":"4"}},[_c('Guideline',{attrs:{"guideline-item":guidelineItem}})],1)}),1),_c('b-modal',{attrs:{"id":"policy-modal","title":"Policy Notice","hide-footer":"","hide-header":"","size":"lg","no-close-on-backdrop":""}},[_c('div',{staticClass:"p-2"},[_c('h1',{staticClass:"text-primary"},[_vm._v("Terms and Conditions")]),_c('b-row',{staticClass:"mb-2 mt-3"},[_c('b-col',[_c('b-button',{staticClass:"w-100 text-primary custom-button",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.openModal(
                'https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/REFUND_POLICY.docx&embedded=true',
                'Refund Policy'
              )}}},[_vm._v(" Refund Policy ")])],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('b-button',{staticClass:"w-100 text-primary custom-button",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.openModal(
                'https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/DATA_PRIVACY_POLICY.docx&embedded=true',
                'Data Privacy Policy'
              )}}},[_vm._v(" Data Privacy Policy ")])],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('b-button',{staticClass:"w-100 text-primary custom-button",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.openModal(
                'https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/RULES_CONDUCT_POLICY.docx&embedded=true',
                'Rules & Conduct Policy'
              )}}},[_vm._v(" Rules & Conduct Policy ")])],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('b-button',{staticClass:"w-100 text-primary custom-button",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.openModal(
                'https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/PRIVACY_TERMS_POLICY.docx&embedded=true',
                'Privacy Terms Policy'
              )}}},[_vm._v(" Privacy Terms Policy ")])],1)],1),_c('b-row',{staticClass:"justify-content-end align-items-center"},[_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"cols":"auto"}},[_c('span',{staticClass:"text-primary",staticStyle:{"cursor":"pointer"},on:{"click":_vm.rejectPolicy}},[_vm._v(" Cancel ")])]),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.acceptPolicy}},[_vm._v("Accept")])],1)],1)],1)]),_c('b-modal',{attrs:{"title":_vm.modalTitle,"size":"lg","hide-footer":""},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('iframe',{attrs:{"src":_vm.docUrl,"width":"100%","height":"750px","frameborder":"0"}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }